<template>
    <div class="card-table-header">
        <ul class="request__ul new-request__ul">
            <li @click.prevent="setActive(sort.id)" v-for="(sort, index) in filterOptions" :key="index" 
                class="request__li" :class="[mode ? 'request__liday' : 'request__linight',{'request__active': isActive(sort.id)}]">
                <a class="d-flex" style="flex-direction: column; justify-content: space-between;">
                    <p style="font-size:14px;">{{ sort.name }}</p>
                    <div element-loading-spinner="el-icon-loading" v-loading="waitingDataLoad">
                        <div v-if="!waitingDataLoad" style="text-align: center;" >
                            <span class="mb-1 d-block"  style="font-size:14px;">{{ $t('message.payments') }}: {{ sort.count || 0 }} </span>
                            <span style="font-size:14px;">{{ $t('message.total') }} {{ sort.total | formatMoney(2) || 0 }} </span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions} from 'vuex';

export default {
    name: 'filterTable',
    data: () => ({
        active: -1,
        waitingDataLoad: true,
        filterOptions: []
    }),
    watch: {
        active: {
            handler: async function(newVal, oldVal) {
                if(newVal) this.$emit('updateActiveFilter', newVal);
            },
            deep: true,
            immediate: true
        },
        filterOptionsData: {
            handler: async function(newVal, oldVal) {
                this.filterOptions = JSON.parse(JSON.stringify(newVal));
                this.waitingDataLoad = false;
            },
            deep: true,
            immediate: true
        },
    },
    mounted(){
        this.updateFilterOptions();
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            filterOptionsData: "costTransactions/filterOptions",
        }),
    },
    methods: {
        ...mapActions({
            updateFilterOptions: "costTransactions/getFilterOptions",
        }),
        isActive (menuItem) {
            return this.active === menuItem
        },
        setActive (menuItem) {
            this.active = menuItem
        },
        
    }
}
</script>

<style>
.mew-style-khan{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 99%;
}
.new-request__ul li{
    width: 140px;
    padding: 10px 8px;
    margin-right: 0.5% !important;
    margin-bottom: 7px;
}
.new-request__ul li a {
    justify-content: start;
    text-align: center;
 }
 .new-request__ul{
    justify-content: center;
 }
</style>
